@import "./variables-minimal.less";
@import "./components.less";

@brand-primary: #f30000;
@brand-primary: #D24C4A;

.block {
 overflow: hidden;
}

/* navbar */
.app-navbar {
  font-size: 13px;
  letter-spacing: .15em;
}
.app-navbar .navbar-brand {
  font-size: 13px;
}

/* intro block */

.app-block-intro {
  padding-bottom: 0;
}

.app-block-intro + .block-bordered-lg:before {
  border-top: none;
}

.app-block-intro {
  padding-top: 20px;
}
.app-block-intro img.screen {
  width: 100%;
  //margin-bottom: -20%;
}

.app-equal-buttons button, .app-equal-buttons a {
  width: 150px;
}

img.app-icon {
  width: 96px;
}

/* block img */
.app-block-game img,
.app-block-stats img {
  width: 100%;
}
@media (max-width: 480px) {
  .app-block-game-img {
    transform: scale(1.5) !important;
  }
}

/* stats hr */
.app-block-stats hr {
  width: calc(80% + 60px);
}

/* design block */
.app-block-design > .container > .row {
  z-index: 2;
}

.app-block-design-img {
  margin-top: -60px;
  margin-left: -450px;
  float: left;
  width: 100%;
}

@media (min-width: 768px) {
  .app-block-design-img {
    margin-top: -220px;
    margin-left: 0;
  }
}

/* footer block */
.app-block-footer {
  background-color: rgb(27, 28, 29);
  color: #444;
  color: rgba(116, 119, 123, .5);
}

/* transforms */
.app-translate-15 {
  -webkit-transform: translateY(15px);
      -ms-transform: translateY(15px);
          transform: translateY(15px);
}
.app-translate-50 {
  -webkit-transform: translateY(50px);
      -ms-transform: translateY(50px);
          transform: translateY(50px);
}
.app-translate-5 {
  -webkit-transform: translateY(5%);
      -ms-transform: translateY(5%);
          transform: translateY(5%);
}
.app-translate-10 {
  -webkit-transform: translateY(10%);
      -ms-transform: translateY(10%);
          transform: translateY(10%);
}


/* brand */

@media (max-width: 400px) {
  .app-taskpaper-brand {
    font-size: 46px !important;
  }
}
@media (max-width: 350px) {
  .app-taskpaper-brand {
    font-size: 42px !important;
  }
}
